import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: [
      'Hind',
      'sans-serif'
    ].join(','),
    h1: {
      fontFamily: 'Archivo Black'
    },
    h2: {
      fontFamily: 'Archivo Black'
    },
    h3: {
      fontFamily: 'Archivo Black'
    },
    h4: {
      fontFamily: 'Archivo Black'
    },
    h5: {
      fontFamily: 'Archivo Black'
    },    
    h6: {
      fontFamily: 'Archivo Black'
    }
  },
  palette: {
    primary: {
      main: '#303c6c',
    },
    secondary: {
      main: '#303c6c',
    },
    error: {
      main: "#F4976C",
    },
    info: {
      main: '#B4DFE5',
      contrastText: '#fff'
    },
    warning: {
      main: '#FBE8A6',
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)"
    },
  },
});

export default theme;